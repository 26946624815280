<template>
  <div class="mobile-sort d-flex align-end" v-bind="$attrs">
    <icon-button v-if="reverse" class="mr-2" @click="nextStatus">
      <v-icon>{{ mobileSortIcon }}</v-icon>
    </icon-button>

    <mobile-sort-menu
      :class="{ 'mr-2': reverse }"
      :sort-list="sortList"
      :sort="sortBy"
      :menu-props="menuProps"
      @update:sort="updateSort"
    />

    <icon-button v-if="!reverse" class="ml-2" @click="nextStatus">
      <v-icon>{{ mobileSortIcon }}</v-icon>
    </icon-button>

    <slot></slot>
  </div>
</template>

<script>
// Components
import IconButton from '@/components/IconButton.vue';
import MobileSortMenu from '@/components/MobileSort/Menu.vue';

// Utils
import { createModelWrapper } from '@/utils/components';

// Constants
import { mobileSortIcons } from '@/constants/sortStatuses';

export default {
  name: 'MobileSort',

  components: { IconButton, MobileSortMenu },

  props: {
    sortList: { type: Array, required: true },
    sortBy: { type: Object, default: () => ({}) },
    options: { type: Object, required: true },
    menuProps: { type: Object, default: () => ({}) },
    reverse: { type: Boolean, default: false },
  },

  computed: {
    optionsModel: createModelWrapper('options', 'update:options'),

    mobileSortIcon() {
      if (this.optionsModel.sortDesc === true) return mobileSortIcons.decreasing;
      if (this.optionsModel.sortDesc === false) return mobileSortIcons.ascending;
      return mobileSortIcons.default;
    },
  },

  methods: {
    nextStatus() {
      if (this.optionsModel.sortDesc === true) this.optionsModel.sortDesc = undefined;
      else if (this.optionsModel.sortDesc === false) this.optionsModel.sortDesc = true;
      else if (this.optionsModel.sortDesc === undefined) this.optionsModel.sortDesc = false;
    },

    updateSort(newSort) {
      this.optionsModel = { sortBy: newSort.value, sortDesc: false };
    },
  },
};
</script>
<style lang="scss">
.mobile-sort {
  flex-grow: 1;
}
</style>
